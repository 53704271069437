<script lang="ts">
export default {
  name: "DefaultLayout",
};
</script>
<script lang="ts" setup>
// Navigation for default theme (depth 1 is 2 level navi, depth 2 is 3 level navi, and so on)
const {loadNavigationElements} = useNavigation();

const {data} = useAsyncData("mainNavigation", () => {
  return loadNavigationElements({depth: 1});
});

provide("swNavigation-main-navigation", data);

const {loadNavigationElements: loadFooterNavigationElements} = useNavigation({
  type: "footer-navigation",
});

const {data: footerData} = useAsyncData("mainFooterNavigation", () => {
  return loadFooterNavigationElements({depth: 1});
});

provide("swNavigation-footer-navigation", footerData);

</script>
<template>
  <v-app>
    <LayoutHeader :canShowBanner="true"/>
    <v-main>
      <slot/>
      <LayoutNotifications/>
      <LayoutFooter/>
    </v-main>
  </v-app>
</template>
